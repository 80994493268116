import React, {useEffect, useState} from "react";
import styles from './style.module.css'
import {useHistory} from "react-router-dom";
import {config, myAxios} from "../../config";

const UserDetail = () => {
    const history = useHistory();

    const [user, setUser] = useState({
        displayName: "",
        email: "",
        role: []
    });

    const [profilePic, setProfilePic] = useState('');

    useEffect(() => {
        getProfilePicture();
        setUser(JSON.parse(localStorage.getItem('authenticatedUser') as string))
    },[])

    const logout = () => {
        localStorage.clear();
        history.push('/')
    }

    const getProfilePicture = () => {
        const user = JSON.parse(localStorage.getItem('authenticatedUser') as string);
        myAxios.get(`${config.AUTH_URL}/photo/${user.oid}`).then((res) => {
            // @ts-ignore
            if (!res.config.url) {
                setProfilePic('');
            } else {
                validateImage(res.config.url).then((imageUrl) => {
                    setProfilePic(imageUrl);
                }, () => {
                    setProfilePic('');
                })
            }
        })
    }

    const validateImage = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => {
                resolve(url);
            });
            image.addEventListener('error', () => {
                reject();
            })
            image.src = url;
        })
    }

    return (

        <div className={styles.popup}>
            <div className={styles.userDetail}>
                <div className={styles.initialsCircle}>
                    {user.displayName && (profilePic === '' || !profilePic) ?
                        <p className={styles.userInitials}>{user?.displayName?.split(' ')[0][0] + user?.displayName?.split(' ')[1][0]}</p>
                        : <img style={{borderRadius: '50%'}} className={styles.profilePic} src={profilePic} alt="profile"/>}
                </div>
                <div className={styles.userFullName}>{user.displayName}</div>
                <div className={styles.logoutButton}>
                    <button onClick={logout}>
                        ODHLÁSIŤ SA
                    </button>
                </div>
            </div>

        </div>
    )
}

export default UserDetail;
