import React, {useEffect, useState} from 'react';
import logo from '../../styles/ESTEN_logo.png'
import styles from './style.module.css'
import UserDetail from "../UserDetail";
import {config, myAxios} from "../../config";

const Header = () => {
    const [user, setUser] = useState({
        displayName: "",
        email: "",
        role: []
    });

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('authenticatedUser') as string))
        getProfilePicture();
    }, [])

    const [popup, setPopup] = useState(false);
    const [profilePic, setProfilePic] = useState('');

    const showPopup = () => {
        setPopup(!popup);
    }

    const getProfilePicture = () => {
        const user = JSON.parse(localStorage.getItem('authenticatedUser') as string);
        myAxios.get(`${config.AUTH_URL}/photo/${user.oid}`).then((res) => {
            // @ts-ignore
            if (!res.config.url) {
                setProfilePic('');
            } else {
                validateImage(res.config.url).then((imageUrl) => {
                    setProfilePic(imageUrl);
                }, () => {
                    setProfilePic('');
                })
            }
        })
    }

    const validateImage = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => {
                resolve(url);
            });
            image.addEventListener('error', () => {
                reject();
            })
            image.src = url;
        })
    }

    return (
        <>
            <div className={styles.header}>
                <div className="w-full flex">
                    <div className="flex justify-between w-full">
                        <img className={styles.logo} src={logo} alt="logo"/>
                        <div className='flex justify-between items-center'>
                            <div className={styles.initialsCircle} onClick={showPopup}>
                                {user.displayName && (!profilePic || profilePic === '') ?
                                    <p className={styles.userInitials}>{user?.displayName?.split(' ')[0][0] + user?.displayName?.split(' ')[1][0]}</p> :
                                    <img style={{borderRadius: '50%'}} src={profilePic} alt="Profile"/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {popup ? <UserDetail/> : null}
        </>
    )
}

export default Header;
