import {config} from "../../config";
import styles from './style.module.css'
import MsLogin from './ms-login.svg'

const Login = () => {
    const initLogin = () => {
        localStorage.clear();
        const state: string = Date.now().toString(36) + Math.random().toString(36).substring(2);
        localStorage.setItem('ms-state', state);
        window.location.href = `${config.AUTH_URL}/authorize?app_id=${config.AUTH_APP_ID}&state=${state}`;
    };

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.loginCard}>
                <h1>
                    Prihlásenie
                </h1>

                <p className="login-info">
                    Pre prihlásenie sa do <strong>Esten aplikácií</strong> použite svoj Microsoft účet.
                </p>
                <div className={styles.loginButton} >
                    <img onClick={initLogin} src={MsLogin} alt="ms-login-button"/>
                </div>
            </div>
        </div>
    )
}

export default Login;
