import {config, myAxios, Routes} from "../../config";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {toast} from 'react-toastify';
import styles from "../Login/style.module.css";

interface User {
    email: string,
    displayName: string,
    roles: string[],
    oid: string;
}

const ProcessLogin = () => {
    const history = useHistory();
    const search = useLocation().search;
    // @ts-ignore
    const code: string = new URLSearchParams(search).get("code");
    // @ts-ignore
    const state: string = new URLSearchParams(search).get("state");


    useEffect(() => {
        processLogin(code, state);
    })

    const processLogin = (code: string, state: string) => {
        const savedState = localStorage.getItem('ms-state');

        if (savedState && savedState === state) {
            myAxios.post(`${config.AUTH_URL}/token?grant_type=authorization_code&app_id=${config.AUTH_APP_ID}&code=${code}`, {})
                // @ts-ignore
                .then((response: TokenResponse) => {
                    const { data } = response;
                    localStorage.clear();
                    localStorage.setItem('accessToken', data.access_token);
                    localStorage.setItem('refreshToken', data.refresh_token);
                    localStorage.setItem('authenticatedUserTokens', JSON.stringify({
                        accessToken: data.access_token,
                        refreshToken: data.refresh_token
                    }));
                    const decodedToken = jwt_decode(data.access_token);
                    const loggedUser: User = {
                        // @ts-ignore
                        email: decodedToken.preferred_username,
                        // @ts-ignore
                        displayName: decodedToken.name,
                        // @ts-ignore
                        roles: decodedToken.roles,
                        // @ts-ignore
                        oid: decodedToken.oid
                    };

                    if (loggedUser.roles === undefined) {
                        history.push(Routes.LOGIN);
                        toast.error("Nemáte priradenú žiadnu rolu. Kontaktujte administrátora.", {position: toast.POSITION.TOP_RIGHT});
                        return;
                    }
                    localStorage.setItem('authenticatedUser', JSON.stringify(loggedUser));
                    history.push('/dashboard');
                    toast.success("Prihlásenie prebehlo úspešne.");
                }, () => {
                    toast.error("Prihlásenie nebolo úspešné, opakujte akciu neskôr.", {position: toast.POSITION.TOP_RIGHT});
                    history.push(Routes.LOGIN);
                });
        } else {
            toast.error("Prihlásenie nebolo úspešné, opakujte akciu neskôr.", {position: toast.POSITION.TOP_RIGHT});
            history.push(Routes.LOGIN);
        }
    }

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.loginCard}>
                <h1>
                    Prihlásenie
                </h1>

                <p className="login-info">
                    Prebieha prihlásenie, počkajte prosím.
                </p>
            </div>
        </div>
    )
}
export default ProcessLogin;
