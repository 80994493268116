// LOCAL
const localPort = 4200;
const localUrl = `http://localhost:${localPort}`;
const localAuthUrl = `https://esapp.devapp.sk:30279/auth`;

const localConfig = {
    AUTH_URL: localAuthUrl,
    DOMAIN_URL: 'https://esapp.devapp.sk:30279',
    AUTH_APP_ID: 'dev',
    IS_DEV: true
};
//---------------------------------------------------------------
// DEV
const devUrl = 'https://esapp.devapp.sk:30279';
const devAuthUrl = `${devUrl}/auth`;

const devConfig = {
    AUTH_URL: devAuthUrl,
    DOMAIN_URL: devUrl,
    AUTH_APP_ID: 'esapp-dashboard',
    IS_DEV: true
};

//---------------------------------------------------------------
// PROD
const prodUrl = 'https://esapp.esten.sk';
const prodAuthUrl = `${prodUrl}/auth`;

const prodConfig = {
    AUTH_URL: prodAuthUrl,
    DOMAIN_URL: prodUrl,
    AUTH_APP_ID: 'esapp-dashboard',
    IS_DEV: false

};
//---------------------------------------------------------------

const environment = process.env.REACT_APP_ENV_TYPE;
let configToExport;

if (environment === 'prod') {
    configToExport = prodConfig;
} else if (environment === 'dev') {
    configToExport = devConfig;
} else {
    configToExport = localConfig;
}

//export
export const config = configToExport;
