/*
* Axios instance with interceptors
*
* */
import axios from 'axios';
import {config, Endpoints} from "./index";

const instance = axios.create({
    baseURL: config.DOMAIN_URL
});

instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers = { 'Authorization': `Bearer ${accessToken}` };

    return config
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use((response) => {
        return response
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;
            return axios.post(Endpoints.refreshToken(localStorage.getItem('refreshToken')),{})
                .then(res => {
                    if (res.status === 201) {
                        // 1) put token to LocalStorage
                        localStorage.setItem('accessToken', res.data);

                        // 2) Change Authorization header
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

                        // 3) return originalRequest object with Axios.
                        return axios(originalRequest);
                    }
                }).catch(error => {
                    localStorage.clear();
                    return Promise.reject(error)
                })
        }
    })


export default instance;
