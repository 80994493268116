import React, {useEffect} from 'react';
import './App.css';
import Dashboard from "./components/Dashboard";
import Login from './components/Login'
import {Route, Switch, useHistory} from "react-router-dom";
import ProcessLogin from "./components/ProcessLogin";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const history = useHistory();

    useEffect(() => {
        redirectLoggedUser();
    })

   let isLoggedUser = () =>   {
       return !!localStorage.getItem('accessToken');
   }

   const redirectLoggedUser = () => {
       isLoggedUser() ? history.push('/dashboard') : history.push('/');
    }

    return(
        <>
            <Switch>
                <Route exact path="/process-login">
                    <ProcessLogin />
                </Route>
                <Route exact path="/">
                    <Login/>
                </Route>
                <Route exact path="/dashboard">
                    <Dashboard  />
                </Route>
            </Switch>
            <ToastContainer />
        </>
    )
}

export default App;
