import styles from "./style.module.css";
import React from "react";


const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className="flex justify-center items-center h-full">
                <a rel="noreferrer" target={"_blank"} className={styles.link} href="https://esten.sk">Esten s.r.o</a>
            </div>
        </div>
    )
}

export default Footer;
