import React, {ReactElement} from "react";
import styles from './style.module.css'
interface Props {
    name: string;
    color: any;
    icon: ReactElement;
    textColor: any;
    link: string;
}

const Card = (props: Props) => {
    return (
        <a href={props.link}>
            <div className={styles.card} style={{backgroundColor: props.color}}>
                <span style={{fontSize: '3em', color: 'white', marginLeft: '10px'}}>{props.icon}</span>
                <span style={{color: props.textColor, marginRight: '10px'}} className="text-2xl font-bold">{props.name}</span>
            </div>
        </a>

    )
}

export default Card;
